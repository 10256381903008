// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-css-modules-js": () => import("./../../../src/pages/about-css-modules.js" /* webpackChunkName: "component---src-pages-about-css-modules-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-geg-js": () => import("./../../../src/pages/contact_geg.js" /* webpackChunkName: "component---src-pages-contact-geg-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-geweestengoedgekeurd-js": () => import("./../../../src/pages/geweestengoedgekeurd.js" /* webpackChunkName: "component---src-pages-geweestengoedgekeurd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pro-001-bell-ringing-js": () => import("./../../../src/pages/PRO001/BellRinging.js" /* webpackChunkName: "component---src-pages-pro-001-bell-ringing-js" */),
  "component---src-pages-pro-001-cell-linking-js": () => import("./../../../src/pages/PRO001/CellLinking.js" /* webpackChunkName: "component---src-pages-pro-001-cell-linking-js" */),
  "component---src-pages-pro-001-js": () => import("./../../../src/pages/PRO001.js" /* webpackChunkName: "component---src-pages-pro-001-js" */),
  "component---src-pages-pro-001-rotary-dial-js": () => import("./../../../src/pages/PRO001/RotaryDial.js" /* webpackChunkName: "component---src-pages-pro-001-rotary-dial-js" */),
  "component---src-pages-pro-002-pro-002-intro-js": () => import("./../../../src/pages/PRO002/PRO002_intro.js" /* webpackChunkName: "component---src-pages-pro-002-pro-002-intro-js" */),
  "component---src-pages-pro-002-pro-002-nodemcuv-2-js": () => import("./../../../src/pages/PRO002/PRO002_Nodemcuv2.js" /* webpackChunkName: "component---src-pages-pro-002-pro-002-nodemcuv-2-js" */),
  "component---src-pages-pro-002-pro-002-test-setup-js": () => import("./../../../src/pages/PRO002/PRO002_TestSetup.js" /* webpackChunkName: "component---src-pages-pro-002-pro-002-test-setup-js" */),
  "component---src-pages-pro-003-pro-003-servo-range-js": () => import("./../../../src/pages/PRO003/PRO003_ServoRange.js" /* webpackChunkName: "component---src-pages-pro-003-pro-003-servo-range-js" */),
  "component---src-pages-pro-003-pro-003-vehicle-dynamics-js": () => import("./../../../src/pages/PRO003/PRO003_VehicleDynamics.js" /* webpackChunkName: "component---src-pages-pro-003-pro-003-vehicle-dynamics-js" */),
  "component---src-pages-pro-004-pro-004-intermediate-steps-js": () => import("./../../../src/pages/PRO004/PRO004_IntermediateSteps.js" /* webpackChunkName: "component---src-pages-pro-004-pro-004-intermediate-steps-js" */),
  "component---src-pages-pro-004-pro-004-project-requirements-js": () => import("./../../../src/pages/PRO004/PRO004_ProjectRequirements.js" /* webpackChunkName: "component---src-pages-pro-004-pro-004-project-requirements-js" */)
}

